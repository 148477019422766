* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mention-tag {
  position: relative;
  cursor: pointer;
  color: #6A2C70;
  font-weight: 600;
  text-decoration: none !important;              
}

.tox .tox-autocompleter .tox-menu {
  max-width: 255px;
  width: 255px;
  height: 125px;
  padding: 8px 0 !important;
  overflow-y: auto;
}

.tox .tox-collection--list .tox-collection__item {
  padding: 8px 8px 8px 12px !important;
}

.tox .tox-collection__item-container--row {
  max-width: 100%;
}

.tox .tox-collection__item-label:first-child {  
  flex: 1 1 100%;
  font-size: 12px;
  font-family: Lato;
  letter-spacing: 0.25px;
  line-height: 14.4px;
  color: rgb(106, 44, 112, 0.9);  
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;    
}

.tox .tox-collection__item-label:last-child {  
  flex: 0 0 70px;
  font-family: Lato;
  text-align: right;
  color: #BDBDBD;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 14.4px;
}

.tox .tox-collection__item-label .tox-autocompleter-highlight {
  color: #6A2C70;
}